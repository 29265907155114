import React, { useMemo } from 'react';
import { useSelect } from 'downshift';
import './select.scss';

interface Props {
  width: number;
  options: SelectOption[];
  defaultValue: string | number | null;
  onChange: (value: string | number | null) => void;
}
type SelectOption = NameValueOption | string | number;
interface NameValueOption {
  name: string;
  value: string | number;
}

export default ({ width, options, defaultValue, onChange }: Props) => {
  const items = useMemo<NameValueOption[]>(() => {
    if (options.length === 0) return [];
    return typeof options[0] === 'object'
      ? (options as NameValueOption[])
      : options.map(v => ({ name: `${v}`, value: v as string | number }));
  }, [options]);
  const {
    isOpen,
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    defaultSelectedItem: defaultValue
      ? items.find(i => i.value === defaultValue)
      : null,
    onSelectedItemChange: changes =>
      onChange(changes.selectedItem ? changes.selectedItem.value : null),
  });

  return (
    <div className={`select ${isOpen ? 'active' : ''}`} style={{ width }}>
      <button {...getToggleButtonProps({ className: 'select-toggle' })}>
        {selectedItem ? selectedItem.name : 'Select an item'}
      </button>
      <ul {...getMenuProps({ className: 'select-menu' })}>
        {items.map((item, index) => (
          <li
            key={item.value}
            {...getItemProps({
              item,
              index,
              className: `select-option ${
                index === highlightedIndex ? 'selected' : ''
              }`,
            })}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
