import { useState, useRef, useEffect } from 'react';

export function useElementVisibility<T extends Element>(
  opts?: IntersectionObserverInit
) {
  const [visible, setVisible] = useState(false);
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    const options = {
      rootMargin: '0px',
      threshold: 0.0,
      ...opts,
    };

    const observer = new IntersectionObserver(
      entries => setVisible(entries[0].isIntersecting),
      options
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref, setVisible]);

  return { visible, ref };
}
