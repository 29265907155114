import React from 'react';
import './step.scss';

interface Props {
  digit: number;
  name: string;
  title: string;
  padTop?: number;
  padBottom?: number;
  children: any;
}

export default ({
  digit,
  name,
  title,
  padTop = 0,
  padBottom = 0,
  children,
}: Props) => {
  const style = { paddingTop: 80 + padTop, paddingBottom: 80 + padBottom };
  return (
    <div className="step">
      <div className="step-left" style={style}>
        <div>
          <div className="heading">
            <div>step</div>
            <div className="digit">{digit}</div>
            <div>{name}</div>
          </div>
          <h3>{title}</h3>
        </div>
      </div>
      <div className="step-right" style={style}>
        <div>{children}</div>
      </div>
    </div>
  );
};
