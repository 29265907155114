import React from 'react';
import './ledger.scss';

interface Props {
  title: string;
  children?: any;
}

export default ({ title, children }: Props) => (
  <div className="ledger">
    <div className="ledger-section">
      <h3 className="ledger-heading">{title}</h3>
    </div>
    {children}
  </div>
);
