import React from 'react';
import { useSelector } from '../store';
import { getIsEstimateReady } from '../store/estimate';
import { COLORS } from '../colors';
import './get-started.scss';

import Layout from '../components/layout';
import Hero from '../components/get-started/hero';
import Appraisal from '../components/get-started/appraisal';
import StepOne from '../components/get-started/step-one';
import StepTwo from '../components/get-started/step-two';
import Status, { RevealMe } from '../components/get-started/summary/status';
import Summary from '../components/get-started/summary';
import ScrollReveal from '../components/scroll-reveal';

export default () => {
  const isEstimateReady = useSelector(getIsEstimateReady);

  const shade = (
    <>
      <StepOne />
      <StepTwo />
    </>
  );

  const content = <RevealMe />;

  return (
    <Layout>
      <div className="get-started" style={{ background: '#fff' }}>
        <div className="constrain">
          <Hero />
          <Appraisal />
          <section className="hero">
            <h1>
              <span style={{ color: COLORS.black }}>Perfect.</span> Now let’s
              find out how much your lease is worth. We’ll need just two
              numbers.
            </h1>
          </section>
        </div>
        <ScrollReveal
          top="calc(100vh - 240px)"
          shade={shade}
          content={content}
          semaphore={isEstimateReady}
        />
        <Status />
        <Summary />
      </div>
    </Layout>
  );
};
