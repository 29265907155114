import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../store';
import useConstant from 'use-constant';
import './appraisal.scss';

import Select from '../select';
import Slider from 'rc-slider';

import car from '../../images/car.svg';
import calendar from '../../images/calendar.svg';
import {
  getVehicleMake,
  getMonthlyPayment,
  getRemainingPayments,
  setVehicleMake,
  setMonthlyPayment,
  setRemainingPayments,
} from '../../store/estimate';

interface Query {
  allAirtable: {
    edges: ManufacturerEdge[];
  };
}
interface ManufacturerEdge {
  node: {
    data: Manufacturer;
  };
}
interface Manufacturer {
  key: string;
  name: string;
}

export default () => {
  const data: Query = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "manufacturers" } }) {
        edges {
          node {
            data {
              key
              name
            }
          }
        }
      }
    }
  `);

  const remainingPaymentOptions = useConstant(() =>
    new Array(36).fill(0).map((_, idx) => ++idx)
  );
  const vehicleMakes = useConstant(() =>
    data.allAirtable.edges.map(e => e.node.data)
  );
  const vehicleMakeOptions = useConstant(() =>
    vehicleMakes
      .map(d => ({ value: d.key, name: d.name }))
      .sort((a, b) => a.name.localeCompare(b.name))
  );

  const vehicleMake = useSelector(getVehicleMake);
  const monthlyPayment = useSelector(getMonthlyPayment);
  const remainingPayments = useSelector(getRemainingPayments);
  const dispatch = useDispatch();

  return (
    <>
      <div className="appraisal">
        <div className="appraisal-card">
          <div className="header">
            <img src={car} height={35} />
          </div>
          <p>What brand of vehicle are you leasing?</p>
          <Select
            width={260}
            options={vehicleMakeOptions}
            defaultValue={vehicleMake}
            onChange={val => dispatch(setVehicleMake(val))}
          />
        </div>
        <div className="appraisal-card">
          <div className="header">
            <div className="price">
              <span className="currency">$</span>
              {monthlyPayment}
            </div>
          </div>
          <p>What's your current monthly payment?</p>
          <Slider
            className="slider"
            style={{ marginTop: '20px' }}
            min={100}
            max={1000}
            step={5}
            value={monthlyPayment}
            onChange={val => dispatch(setMonthlyPayment(val))}
          />
        </div>
        <div className="appraisal-card">
          <div className="header">
            <img src={calendar} height={35} />
          </div>
          <p>How many payments do you have left?</p>
          <Select
            width={120}
            options={remainingPaymentOptions}
            defaultValue={remainingPayments}
            onChange={val => dispatch(setRemainingPayments(val))}
          />
        </div>
      </div>
      <p className="appraisal__disclaimer">
        This information can be found online or by calling your lender. Monthly
        payments are in $5 increments.
      </p>
    </>
  );
};
