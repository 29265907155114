import React from 'react';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { getEstimate, EstimateState, Estimate } from '../../../store/estimate';
import './result.scss';

export default () => {
  const estimate = useSelector(getEstimate);

  if (
    estimate.state === EstimateState.Pending ||
    estimate.equity >= estimate.replacementCost ||
    (estimate.equity === 0 && estimate.replacementCost === 0)
  ) {
    return <div style={{ height: '300px' }} />;
  }

  const message =
    estimate.state === EstimateState.CantHelp
      ? 'While it may seem less expensive to stay in your lease, we still recommend getting out. Remember, when your lease ends you’ll still need transportation.'
      : estimate.isAbsolutelyBetter
      ? 'The numbers are in your favor. Getting out of your lease would actually save you money.'
      : 'It’s close. While it may seem less expensive to stay in your lease, we still recommend getting out.';

  return (
    <div className="result">
      <div
        className="constrain"
        style={{ maxWidth: '850px', margin: '0 auto' }}
      >
        <h1>Rubber, meet road.</h1>
        <h2 style={{ marginBottom: '5rem' }}>{message}</h2>
        {/* <Link
          className="btn"
          style={{ fontSize: '18px', padding: '10px 20px' }}
          to="/next-steps"
        >
          Show me now
        </Link> */}
        <a 
        className="btn"
        style={{ fontSize: '18px', padding: '10px 20px' }}
        href="https://hamster.autos/store" 
        > 
        Show me how 
        </a>
      </div>
    </div>
  );
};
