import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPayoffQuote, getPayoffQuote } from '../../store/estimate';
import { COLORS } from '../../colors';
import { scrubNumericInput } from '../../utils';

import Step from './step';

export default () => {
  const defaultPayoffQuote = useSelector(getPayoffQuote);
  const dispatch = useDispatch();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setPayoffQuote(scrubNumericInput(e.target.value)));

  return (
    <Step digit={1} name="one" title="Get a payoff quote." padTop={80}>
      <p style={{ fontSize: '20px', color: COLORS.white80, lineHeight: 1.6 }}>
        Your <strong>"payoff quote"</strong> is how much money you’ll need to
        get out of your lease. Don’t worry, you won’t actually owe this much
        money. We just need it as a part of our calculation. Call your leasing
        company (or go online) to get the payoff quote. FYI, sometimes it’s also
        called an <strong>"early buy-out amount"</strong>. Enter that number
        below.
      </p>
      <div style={{ height: '300px' }} />
      <div className="input">
        <input
          type="text"
          placeholder="Insert payoff quote"
          defaultValue={defaultPayoffQuote || ''}
          onChange={onChange}
        />
      </div>
    </Step>
  );
};
