import React from 'react';
import './ledger-section.scss';

interface Props {
  title: string;
  children?: any;
}

export default ({ title, children }: Props) => (
  <div className="ledger-section">
    <h4 className="ledger-title">{title}</h4>
    {children}
  </div>
);
