import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { setVehicleValue, getVehicleValue } from '../../store/estimate';
import { COLORS } from '../../colors';
import { scrubNumericInput } from '../../utils';

import Step from './step';

interface Query {
  allAirtable: {
    edges: Array<{
      node: {
        data: {
          id: number;
          name: string;
          link: string;
        };
      };
    }>;
  };
}

export default () => {
  const data: Query = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "purchasers" } }) {
        edges {
          node {
            data {
              id
              name
              link
            }
          }
        }
      }
    }
  `);

  const defaultVehicleValue = useSelector(getVehicleValue);
  const dispatch = useDispatch();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setVehicleValue(scrubNumericInput(e.target.value)));

  const purchasers = data.allAirtable.edges.map(e => e.node.data);

  return (
    <Step digit={2} name="two" title="What's my vehicle worth?" padTop={64}>
      <p style={{ fontSize: '20px', color: COLORS.white80, lineHeight: 1.6 }}>
        Next, we need to find out the <strong>Actual Cash Value</strong> of your
        lease, or <strong>ACV</strong>. We recommend using one of the websites
        below. Have your 17 digit VIN number handy - it will help determine your
        vehicle’s value. Come back to Hamster and enter that number below. Once
        you're done, scroll down.
        <br />
        <br />
        <span
          style={{
            fontFamily: 'Futura, sans-serif',
            fontSize: '0.8em',
            textTransform: 'uppercase',
            padding: '4px 8px',
            background: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '5px',
          }}
        >
          hint:
        </span>
        <br />
        on Kelley Blue Book, select
        <br />
        <strong>Trade In & Instant Cash Offer</strong>.
      </p>
      <ul style={{ margin: '1.5rem 0 200px' }}>
        {purchasers.map(purchaser => (
          <li key={purchaser.id} style={{ padding: '0.5rem 0' }}>
            <a
              className="link"
              href={purchaser.link}
              target="_blank"
              rel="noopener"
            >
              {purchaser.name}
            </a>
          </li>
        ))}
      </ul>
      <div className="input">
        <input
          type="text"
          placeholder="Vehicle's current value"
          defaultValue={defaultVehicleValue || ''}
          onChange={onChange}
        />
      </div>
    </Step>
  );
};
