import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import { useElementVisibility } from '../../../hooks/useElementVisibility';
import { useSelector } from '../../../store';
import {
  getReplacementCost,
  setReplacementCost,
  getEstimate,
  EstimateState,
} from '../../../store/estimate';
import './lease-or-leave.scss';

import Pills from '../../pills';
import Ledger from '../../ledger';
import LedgerSection from '../../ledger-section';
import LedgerLine from '../../ledger-line';
import Thousands from '../../thousands';
import UtilityBar from '../../utility-bar';
import { COLORS } from '../../../colors';

const SHOW_IDEAS = false;

const replacementVehicles = [
  { value: 2500, name: '$2,500' },
  { value: 5000, name: '$5,000' },
  { value: 10000, name: '$10,000' },
  { value: 0, name: "I won't need one" },
];

interface Query {
  allAirtable: {
    edges: Array<{
      node: {
        data: {
          href: string;
        };
      };
    }>;
  };
}

export default () => {
  const data: Query = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: {
          table: { eq: "contact" }
          data: { provider: { eq: "email" } }
        }
      ) {
        edges {
          node {
            data {
              href
            }
          }
        }
      }
    }
  `);

  const email = data.allAirtable.edges[0].node.data.href;
  const estimate = useSelector(getEstimate);

  // this isn't strictly necessary, but it hints to TS that
  // estimate will always contain all required values
  if (estimate.state === EstimateState.Pending) return null;

  const replacementCost = useSelector(getReplacementCost);
  const dispatch = useDispatch();

  const { ref, visible } = useElementVisibility<HTMLDivElement>({
    rootMargin: '-150px',
  });

  const needsReplacmentVehicle = estimate.replacementCost > 0;

  const leaveSummary =
    estimate.equity > 0 ? (
      !needsReplacmentVehicle ? (
        <span>
          You’ve got positive equity in your lease! Skip the rest of the site
          and go directly to wherever you got your quote. <strong>TODAY</strong>
          . <strong>CONGRATS!</strong> Drop us a line at{' '}
          <a href={`mailto:${email}`}>{email}</a>. We’d love to hear your{' '}
          <em style={{ color: COLORS.blue }}>#leaseorleave</em> story!
        </span>
      ) : estimate.equity >= estimate.replacementCost ? (
        <span>
          You’ve got positive equity in your lease! Skip the rest of the site
          and go directly to wherever you got your quote. <strong>TODAY</strong>
          . Sell your lease and use the money to buy an inexpensive pre-owned
          car! Drop us a line at <a href={`mailto:${email}`}>{email}</a>. We’d
          love to hear your{' '}
          <em style={{ color: COLORS.blue }}>#leaseorleave</em> story!
        </span>
      ) : (
        <span>
          Sell your lease and get an inexpensive pre-owned car. Don’t worry if
          you don’t have the cash on hand. We’ll help with that a bit later. Oh,
          and choosing to leave means <strong>YOU’LL OWN YOUR CAR</strong>. So,
          what could you do without a car payment again?
        </span>
      )
    ) : estimate.equity === 0 ? (
      needsReplacmentVehicle ? (
        <span>
          Sell your lease and get an inexpensive pre-owned car. Don’t worry if
          you don’t have the cash on hand. We’ll help with that a bit later. Oh,
          and choosing to leave means <strong>YOU’LL OWN YOUR CAR</strong>. So,
          what could you do without a car payment again?
        </span>
      ) : (
        <span>
          Skip the rest of the site and go directly to wherever you got your
          quote.
          <strong>TODAY.</strong> <strong>CONGRATS!</strong> Drop us a line at{' '}
          <a href={`mailto:${email}`}>{email}</a>. We'd love to hear your{' '}
          <em style={{ color: COLORS.blue }}>#leaseorleave</em> story!
        </span>
      )
    ) : needsReplacmentVehicle ? (
      <span>
        Sell your lease and get an inexpensive pre-owned car. Don’t worry if you
        don’t have the cash on hand. We’ll help with that a bit later. Oh, and
        choosing to leave means <strong>YOU’LL OWN YOUR CAR</strong>. So, what
        could you do without a car payment again?
      </span>
    ) : (
      <span>
        Sell your lease. Don’t worry if you don’t have the cash on hand. We’ll
        help with that a bit later.
      </span>
    );

  return (
    <>
      <UtilityBar active={visible}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <strong style={{ color: COLORS.blue, marginBottom: '0.5rem' }}>
            LEASE
          </strong>
          <div>
            <span style={{ color: '#aaa' }}>up to </span>
            <span className="dollar-sign">$</span>
            <Thousands value={estimate.costToRemainMax} />
          </div>
        </div>
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
          }}
        >
          <strong style={{ color: COLORS.blue, marginBottom: '0.5rem' }}>
            LEAVE
          </strong>
          <div>
            <span className="dollar-sign">$</span>
            <Thousands value={estimate.costToGetOut} />
          </div>
        </div>
      </UtilityBar>
      <div ref={ref} className="lease-or-leave">
        <div style={{ maxWidth: '850px', margin: '0 auto' }}>
          <h1>Your options.</h1>
          <h2>
            Use the tool below to visualize two different scenarios. You can
            remain in your lease or choose to leave.{' '}
            <span style={{ color: COLORS.dark }}>
              Hint: we recommend leaving.
            </span>
          </h2>
          <div className="columns">
            <Ledger title="lease">
              <LedgerSection title="basic numbers">
                <LedgerLine>
                  <div>
                    <span className="dollar-sign">$</span>
                    <Thousands value={estimate.monthlyPayment} />
                  </div>
                  <div className="op">&times;</div>
                  <div>
                    <Thousands value={estimate.remainingPayments} />
                  </div>
                  <div className="op">=</div>
                  <div>
                    <span className="dollar-sign">$</span>
                    <Thousands value={estimate.payoffViaLease} />
                  </div>
                </LedgerLine>
              </LedgerSection>
              <LedgerSection title="plus">
                <div>
                  Lease-end fees of <span className="dollar-sign">$</span>
                  <Thousands value={estimate.leaseEndFeeMin} /> &mdash;{' '}
                  <Thousands value={estimate.leaseEndFeeMax} />
                </div>
                <div>
                  Taxes &amp; Insurance: <span>$$$</span>
                </div>
              </LedgerSection>
              <LedgerSection title="cost to remain in lease">
                <LedgerLine>
                  <div style={{ fontSize: '28px' }}>
                    <span className="dollar-sign">$</span>
                    <Thousands value={estimate.costToRemainMin} />
                  </div>
                  <div>&mdash;</div>
                  <div style={{ fontSize: '28px' }}>
                    <span className="dollar-sign">$</span>
                    <Thousands value={estimate.costToRemainMax} />
                  </div>
                </LedgerLine>
                <p style={{ fontSize: '16px', lineHeight: '1.2' }}>
                  Continue to lease your vehicle for 18 months at about $
                  {Math.round(estimate.adjustedMonthlyPayment)}
                  /mo plus taxes and insurance. "Lease-end fees" are things like
                  minor damages, mileage overages and tires. When your lease
                  ends you'll be without a vehicle. You’ll either need to lease
                  again (3 more years) or come up with money for a replacement
                  car.
                </p>
              </LedgerSection>
            </Ledger>
            <Ledger title="leave">
              <LedgerSection title="replacement vehicle">
                <Pills
                  options={replacementVehicles}
                  defaultValue={replacementCost}
                  onChange={val => dispatch(setReplacementCost(val))}
                />
              </LedgerSection>
              <LedgerSection title="plus">
                <div>ZERO Lease-end fees</div>
                <div>
                  Taxes &amp; Insurance: <span className="dollar-sign">$</span>
                </div>
              </LedgerSection>
              {estimate.equity < 0 ? (
                <LedgerSection title="basic numbers">
                  <LedgerLine>
                    <div>
                      <span className="dollar-sign">$</span>
                      <Thousands value={Math.abs(estimate.equity)} />
                    </div>
                    {estimate.replacementCost > 0 && (
                      <>
                        <div className="op">+</div>
                        <div>
                          <span className="dollar-sign">$</span>
                          <Thousands value={estimate.replacementCost} />
                        </div>
                      </>
                    )}
                  </LedgerLine>
                </LedgerSection>
              ) : estimate.equity > 0 &&
                estimate.equity < estimate.replacementCost ? (
                <LedgerSection title="basic numbers">
                  <LedgerLine>
                    <div>
                      <span className="dollar-sign">$</span>
                      <Thousands value={estimate.replacementCost} />
                    </div>
                    <div className="op">-</div>
                    <div>
                      <span className="dollar-sign">$</span>
                      <Thousands value={Math.abs(estimate.equity)} />
                    </div>
                  </LedgerLine>
                </LedgerSection>
              ) : null}
              <LedgerSection title="cost to get out">
                <LedgerLine>
                  <div style={{ fontSize: '28px' }}>
                    <span className="dollar-sign">$</span>
                    <Thousands value={estimate.costToGetOut} />
                  </div>
                </LedgerLine>
                <p style={{ fontSize: '16px', lineHeight: '1.2' }}>
                  {leaveSummary}
                </p>
                {SHOW_IDEAS && (
                  <a
                    href="#"
                    className="link"
                    style={{
                      display: 'inline-block',
                      fontWeight: 400,
                      marginTop: '8px',
                    }}
                  >
                    Here are some ideas
                  </a>
                )}
              </LedgerSection>
            </Ledger>
          </div>
        </div>
      </div>
    </>
  );
};
