import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';

interface Props {
  content: any;
  shade: any;
  /** optional value indicating whether the content has resized */
  semaphore?: any;
  top?: number | string;
}

export default ({ content, shade, semaphore, top = 0 }: Props) => {
  const [contentHeight, setContentHeight] = useState(1);
  const [shadeHeight, setShadeHeight] = useState(1);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const shadeRef = useRef<HTMLDivElement | null>(null);

  const measureContents = () => {
    if (!contentRef.current || !shadeRef.current) return;

    setContentHeight(contentRef.current.clientHeight);
    setShadeHeight(shadeRef.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', measureContents);
    return () => window.removeEventListener('resize', measureContents);
  }, []);

  useLayoutEffect(measureContents, [contentRef, shadeRef, semaphore]);

  return (
    <div
      style={{
        position: 'relative',
        height: shadeHeight + contentHeight,
        paddingBottom: contentHeight,
      }}
    >
      <div
        className="scroll-reveal__sticky"
        style={{ position: 'sticky', top }}
      >
        <div
          ref={contentRef}
          style={{
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          {content}
        </div>
      </div>
      <div
        ref={shadeRef}
        style={{
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: 1001,
        }}
      >
        {shade}
      </div>
    </div>
  );
};
