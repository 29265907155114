import React, { useState } from 'react';
import './pills.scss';

interface Props {
  options: Option[];
  defaultValue?: Value;
  onChange?: (val: Value) => void;
}
interface Option {
  name: string;
  value: Value;
}
type Value = string | number;

export default ({ options, defaultValue, onChange }: Props) => {
  const [value, setValue] = useState<Value | null>(defaultValue || null);

  const handleClick = (v: Value) => () => {
    setValue(v);
    if (typeof onChange === 'function') onChange(v);
  };

  return (
    <div className="pills">
      {options.map((opt, idx) => (
        <div
          key={idx}
          className={`pill ${opt.value === value ? 'active' : ''}`}
          onClick={handleClick(opt.value)}
        >
          {opt.name}
        </div>
      ))}
    </div>
  );
};
