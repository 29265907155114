import React from 'react';
import './utility-bar.scss';

interface Props {
  active: boolean;
  children?: any;
}

export default ({ active, children }: Props) => (
  <div className={`utility-bar ${active ? 'active' : ''}`}>{children}</div>
);
