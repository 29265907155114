import React from 'react';

interface Props {
  value: number;
}

/**
 * `Number.prototype.toLocaleString` will add thousands separators.
 */
export default ({ value }: Props) => (
  <span>{typeof value !== 'number' ? 0 : value.toLocaleString()}</span>
);
