import React from 'react';
import { useSelector } from 'react-redux';
import { getEstimate } from '../../store/estimate';

import LeaseOrLeave from './summary/lease-or-leave';
import Result from './summary/result';

export default () => {
  const estimate = useSelector(getEstimate);

  if (estimate.state === 'pending') return null;

  return (
    <div className="constrain">
      <LeaseOrLeave />
      <Result />
    </div>
  );
};
