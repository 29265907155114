import React from 'react';
import './hero.scss';

export default () => (
  <div className="get-started__hero">
    <h1 className="get-started__hero-heading hidden-mobile">
      Let's get started.
    </h1>
    <p className="get-started__hero-text">
      <span className="get-started__hero-heading show-mobile">
        Let's get started.
      </span>
      To help guide you out of your lease we just need some basic information.
      We’ll crunch the numbers so you don’t need to.
    </p>
  </div>
);
