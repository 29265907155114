import React from 'react';
import './ledger-line.scss';

interface Props {
  children: any;
}

export default ({ children }: Props) => (
  <div className="ledger-line">{children}</div>
);
