import React from 'react';
import { useSelector } from 'react-redux';
import { getEstimate, EstimateState } from '../../../store/estimate';
import './status.scss';

import grimace from '../../../images/grimace.svg';
import sunglasses from '../../../images/sunglasses.svg';
import thinking from '../../../images/thinking.svg';
import Swoosh from '../../../svgs/swoosh.svg';
import Thousands from '../../thousands';

function getStatus(equity: number) {
  switch (true) {
    case equity === 0:
      return {
        emotion: 'neutral',
        icon: sunglasses,
        status: "It's a wash!",
        header: 'No negative equity!',
        details: 'This makes getting out of your lease even easier!',
      };
    case equity > 0:
      return {
        emotion: 'happy',
        icon: sunglasses,
        status: "It looks like you've got positive equity in your car!",
        header: 'This is really good news.',
        details: 'This makes getting out of your lease even easier!',
      };
    case equity < 0:
      return {
        emotion: 'sad',
        icon: grimace,
        status: 'It looks like you owe more than your car is worth.',
        header: 'OK, take a breath.',
        details: `This is pretty common. ​​It’s referred to as being “upside down”.
                  But don’t worry, Lease or Leave was designed for situations just like yours.`,
      };
  }

  throw new Error('non-exhaustive switch case');
}

export const RevealMe = () => {
  const estimate = useSelector(getEstimate);

  const { icon, status } =
    estimate.state === EstimateState.Pending
      ? {
          icon: thinking,
          status: 'Hmm, looks like you may have left some fields blank.',
        }
      : getStatus(estimate.equity);

  return (
    <div className="status">
      <div className="status__heading">
        <h1>{status}</h1>
        <div className="icon">
          <img src={icon} width={100} height={100} />
        </div>
      </div>
    </div>
  );
};

export default () => {
  const estimate = useSelector(getEstimate);

  // this isn't strictly necessary, but it hints to TS that
  // estimate will always contain all required values
  if (estimate.state === EstimateState.Pending) return null;

  const { emotion, header, details } = getStatus(estimate.equity);

  return (
    <div className="status">
      <div className={`status__estimate ${emotion}`}>
        <div className="price">
          <span className="dollar-sign">$</span>
          {estimate.equity === 0 ? (
            '0.00'
          ) : (
            <Thousands value={Math.abs(estimate.equity)} />
          )}
        </div>
        <Swoosh className="swoosh" width={170} />
        <div className="status__divider" />
        <div className="status__details">
          <h1 style={{ display: 'inline' }}>{header}</h1>{' '}
          <h2 style={{ display: 'inline' }}>{details}</h2>
        </div>
      </div>
    </div>
  );
};
